import firebase from 'firebase/compat/app';
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";

const firebaseConfig = {
    apiKey: "AIzaSyAWdtndNEO7n9ryKlw5az6I27-PmhCgVbo",
    authDomain: "vip-meet.firebaseapp.com",
    projectId: "vip-meet",
    storageBucket: "vip-meet.appspot.com",
    messagingSenderId: "1830020829",
    appId: "1:1830020829:web:0671169144182535c2dd34",
    measurementId: "G-3DNM49VJHF"
  }

firebase.initializeApp(firebaseConfig);

export default firebase

