<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark v-bind="attrs" v-on="on" icon>
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </template>
    <v-list dense>
      <v-subheader v-if="user"> {{ user.displayName }} </v-subheader>

      <v-list-item-group>
        <v-list-item v-if="!user" @click="login">
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-title> Login </v-list-item-title>
        </v-list-item>

        <v-list-item v-if="user" to="/create">
          <v-list-item-icon>
            <v-icon>mdi-calendar-today</v-icon>
          </v-list-item-icon>
          <v-list-item-title> Create Link </v-list-item-title>
        </v-list-item>

        <v-list-item v-if="user" to="/profile">
          <v-list-item-icon>
            <v-icon>mdi-account-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-title> Profile </v-list-item-title>
        </v-list-item>

        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-information</v-icon>
          </v-list-item-icon>
          <v-list-item-title> About </v-list-item-title>
        </v-list-item>

        <v-list-item v-if="user" @click="logout">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title> Logout </v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import firebase from "../firebase";
import { mapState } from "vuex";
const db = firebase.firestore();

let provider = new firebase.auth.OAuthProvider("microsoft.com");
provider.setCustomParameters({
  prompt: "consent",
});
provider.setCustomParameters({
  tenant: "4054b496-d6ce-49ae-a59a-b9508270f922",
});

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  methods: {
    async logout() {
      await firebase.auth().signOut();
    },
    async login() {
      if (this.user) {
        this.logout();
        this.user = null;
        return;
      }
      let result = await firebase.auth().signInWithPopup(provider);
//&& (page==request.auth.email || resource.data.email == null || resource.data.email == request.auth.email ) 
      let credential = result.credential;
//&& (page==request.auth.email || resource.data.email == null || resource.data.email == request.auth.email )
      // OAuth access and id tokens can also be retrieved:
      let accessToken = credential.accessToken;
      let idToken = credential.idToken;
      console.log(accessToken, idToken, credential, this.user);
      db.collection("profiles").doc(this.user.email).set(
        {
          email: this.user.email,
          displayName: this.user.displayName,
          uid: this.user.uid,
        },
        { merge: true }
      );
    },
  },
  created() {
    // firebase.auth().onAuthStateChanged((user)=>{
    //     console.log({user})
    //     this.user = user
    // })
  },
};
</script>

<style>
</style>