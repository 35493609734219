<template>
  <v-app fluid>
    <div class="d-flex">
      <v-spacer></v-spacer>
    <LoginButton/>
  </div>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import LoginButton from '@/components/LoginButton.vue'
export default {
  name: 'App',
  components: {
    LoginButton
  },
  data: () => ({
    //
  }),
};
</script>
