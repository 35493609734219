<template>
  <div></div>
</template>

<script>
  export default {
    name: 'HomeView',

    components: {
    },
  }
</script>
